#contact-us {
  .form-submit {
    &:hover {
      border: 1px solid $black;
    }
  }
}

@include breakpoint($medium-up) {
  form {
    .form-type-textfield {
      label {
        line-height: 2.8;
      }
    }
    .form-type-textarea {
      .form-textarea-wrapper {
        label {
          line-height: 3;
        }
      }
    }
    .form-item-form-SELECT {
      #edit-form-select {
        width: 97%;
        .select-box__label {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }
    .form-item {
      .form-textarea-wrapper.resizable.textarea-processed.resizable-textarea {
        width: 97%;
      }
    }
    .form-item-form-unsubsctibe {
      padding-bottom: 20px;
    }
  }
}

@include breakpoint($small-down) {
  form {
    .form-type-textfield {
      label {
        line-height: 3.35;
      }
    }
    .form-type-textarea {
      .form-textarea-wrapper {
        label {
          line-height: 3.2;
        }
      }
    }
    .form-item {
      select {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
